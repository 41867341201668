import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { userSignInThunk } from "../store/thunks/authServices";
import LoadingScreen from "../view/LoadingScreen";
import { DefaultCredential } from "../constants/constants";

function PrivateRoute({children, ...rest}) {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(state => state.authServices.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        userSignInThunk({
          username: DefaultCredential.email,
          password: DefaultCredential.password,
          rememberMe: true,
          timeZoneOffset: 0,
        }),
      )
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={({location}) =>
        isLoggedIn ? (
          children
        ) : (
          <LoadingScreen/>
        )
      }
    />
  );
}

export default PrivateRoute
