import {createAsyncThunk} from '@reduxjs/toolkit'
import {getResourceDocs, searchData} from '../../utilities/ApiManage'
import {bodySearch} from '../../view/search/constant/constant'

export const searchContent = createAsyncThunk('content/search', async data => {
  try {
    const res = await searchData(data)
    if (!res.ok) throw new Error(res.message)
    return res
  } catch (e) {}
})

export const getResourceThunk = createAsyncThunk('content/getResources', async data => {
  try {
    const res = await getResourceDocs()
    if (!res.ok) throw new Error(res.message)
    return res.data
  } catch (e) {}
})
