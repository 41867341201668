import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Layout} from 'antd'
import {getChannelsThunk, getResourcesThunk} from '../store/thunks/appServices'
import {getUserFlaggedDocsThunk, getUserInfoThunk, getUserRatingsDocsThunk} from '../store/thunks/authServices'

import LoadingScreen from '../view/LoadingScreen'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'
import {Route, Switch} from "react-router-dom";
import {endUserRoutes, guestRoutes} from "../routes/routes";

const MainLayout = ({Component, pageProps}) => {
  const isLoggedIn = useSelector(state => state.authServices.isLoggedIn)
  const showGlobalIndicator = useSelector(state => state.appServices.showGlobalIndicator)
  const userInfo = useSelector(state => state.authServices.userInfo)
  const isEndUser = useSelector(state => state.authServices.isEndUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getResourcesThunk())
      dispatch(getChannelsThunk())
      dispatch(getUserInfoThunk(userInfo?.bepozId))
      dispatch(getUserFlaggedDocsThunk(userInfo?.bepozId))
      dispatch(getUserRatingsDocsThunk(userInfo?.bepozId))
    }
  }, [dispatch, isLoggedIn, userInfo?.bepozId])

  const routes = isEndUser ? endUserRoutes : guestRoutes

  return (<Layout className={'h-screen flex flex-col'}>
    <HeaderComponent/>
    <Switch>
      <Layout className="flex flex-1 flex-col">
        {routes.map((route, idx) => {
          return (route.component && (<Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => (<route.component {...props} />)}
          />))
        })}
      </Layout>
    </Switch>
    {showGlobalIndicator && <LoadingScreen/>}
    <FooterComponent/>
  </Layout>)
}

export default MainLayout
