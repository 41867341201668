import {Col, Modal} from 'antd'
import {Typography} from 'antd';

const {Title} = Typography;

function CustomModal({visible, onClose, title, children, closable = true}) {
  return (<Modal
    className="rounded"
    style={{borderRadius: 20}}
    visible={visible}
    footer={null}
    centered={true}
    closable={closable}
    onCancel={() => onClose?.(false)}
    bodyStyle={{borderRadius: 20}}>
    <Col type={'flex'} align={'middle'}>
      <Title level={4}>{title}</Title>
      {children}
    </Col>
  </Modal>)
}

export default CustomModal
