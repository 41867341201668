import {combineReducers} from '@reduxjs/toolkit'
import authServicesReducer from './reducers/authServices'
import appServicesReducer from './reducers/appServices'
import contentServicesReducer from './reducers/contentServices'
import {connectRouter} from "connected-react-router";

const rootReducer = (history) =>  combineReducers({
  authServices: authServicesReducer,
  appServices: appServicesReducer,
  contentServices: contentServicesReducer,
  router: connectRouter(history),
})

export default rootReducer
