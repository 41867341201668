import {createSlice} from '@reduxjs/toolkit'
import {REHYDRATE} from 'redux-persist'
import {
  getUserFlaggedDocsThunk,
  getUserInfoThunk, getUserRatingsDocsThunk,
  userSignInThunk,
  userSignUpThunk,
} from '../thunks/authServices'
import {setAccessToken} from '../../utilities/NetworkingAuth'
import {UserRoles} from '../../constants/constants'

// type LikedDocs = {
//     id: 1,
//     bepozId: 10,
//     docId: 300000014,
//     ratings: 2
// }[]

const initialState = {
  userInfo: {},
  tokenData: {},
  isLoggedIn: false,
  flaggedDocs: {
    id: 0,
    bepozId: 0,
    flaggedDocIds: '',
  },
  likedDocs: [],
  userVenues: [],
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.userInfo = {}
      state.tokenData = {}
      state.isLoggedIn = false
    },
    updateUserInfo(state, action) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      }
    },
    updateFlaggedDocs(state, action) {
      state.flaggedDocs = action.payload
    },
    updateLikedDocs(state, action) {
      state.likedDocs = action.payload
    },
    setUserVenues(state, action) {
      state.userVenues = action.payload
    },
    userLoginSuccess(state, action) {
      const {TokenData, userInfo} = action.payload
      setAccessToken(TokenData?.JwtToken)
      state.userInfo = userInfo
      state.tokenData = TokenData
      state.isLoggedIn = true
      state.isEndUser = TokenData.TokenClaims.Roles?.includes(UserRoles.endUser)
    },
    updateRatingDocs(state, action) {
      let data = [...state.ratingsDocs]
      let index = data.findIndex(item => item.docId === action.payload?.docId)
      if (index >= 0) {
        data[index] = action.payload
      } else {
        data.push(action.payload)
      }
      state.ratingsDocs = data
    }
  },
  extraReducers: builder => {
    builder.addCase(userSignUpThunk.fulfilled, (state, action) => {})

    builder.addCase(getUserInfoThunk.fulfilled, (state, action) => {
      state.userInfo = action.payload
    })
    builder.addCase(getUserFlaggedDocsThunk.fulfilled, (state, action) => {
      state.flaggedDocs = action.payload
    })
    builder.addCase(getUserRatingsDocsThunk.fulfilled, (state, action) => {
      state.ratingsDocs = action.payload
    })

    builder.addCase(REHYDRATE, (state, action) => {
      state = action.payload?.authServices
      setAccessToken(action.payload?.authServices?.tokenData?.JwtToken)
    })
  },
})

export const authActions = authSlice.actions
export default authSlice.reducer
