import {createSlice} from '@reduxjs/toolkit'
import {getResourceThunk, searchContent} from '../thunks/contentServices'
import {bodySearch} from '../../view/search/constant/constant'

const initialState = {
  selectedItem: null,
  contents: [],
  filter: bodySearch,
  resources: {
    data: [],
  },
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filter = {
        ...(state.filter || {}),
        ...action.payload,
      }
    },
    setSelectItem: (state, action) => {
      state.selectedItem = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(searchContent.fulfilled, (state, action) => {
      if (action.payload) {
        const {data, opPagination} = action.payload
        if (opPagination?.pageNo > 1) {
          let list = [...(state.contents?.data || []), ...data]
          state.contents = {
            ...action.payload,
            data: list,
          }
        } else {
          state.contents = action.payload
        }
      }
    })
    builder.addCase(getResourceThunk.fulfilled, (state, action) => {
      state.resources = action.payload
    })
  },
})

export const {updateFilter, setSelectItem} = contentSlice.actions
export default contentSlice.reducer
