import React from 'react'
import {Spin} from "antd";

const LoadingScreen = () => {
  return (
    <div className={'loading-screen'}>
      <Spin size="large" />
    </div>
  )
}
export default LoadingScreen
