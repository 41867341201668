import {Row, Typography} from 'antd'

const {Text} = Typography
export const Span = ({children, style, ...rest}) => {
  return (
    <Row>
      <Text {...rest} style={{color: 'white', ...style}}>
        {children}
      </Text>
    </Row>
  )
}
