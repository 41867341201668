import {createAsyncThunk} from '@reduxjs/toolkit'
import {userSignUp, userLogin, getUserInfo, getUserFlaggedDocs, getUserRatingDocs} from '../../utilities/ApiManage'
import {setAuthForm, setShowGlobalIndicator} from '../reducers/appServices'
import {toast} from 'react-toastify'
import {push} from "connected-react-router";
import {authActions} from "../reducers/authServices";
import {updateFilter} from "../reducers/contentServices";

export const userSignUpThunk = createAsyncThunk('auth/signup', async (data, {dispatch}) => {
  try {
    const res = await userSignUp(data)
    if (!res.ok) throw new Error(res.message)
    toast.success(res.message)
    dispatch(setAuthForm('login'))
    return res
  } catch (e) {
    toast.error(e.message || 'Something went wrong!')
  }
})

export const userSignInThunk = createAsyncThunk('auth/signin', async (data, {dispatch}) => {
  try {
    dispatch(setShowGlobalIndicator(true))
    const res = await userLogin(data)
    if (!res.ok) throw new Error(res.message)
    const userRes = await getUserInfo(res?.data?.User?.BepozId)
    dispatch(authActions.userLoginSuccess({
      userInfo: {
        ...userRes.data?.profile,
        photoUrl: userRes.data?.photoUrl,
        backgroundPhotoUrl: userRes.data?.backgroundPhotoUrl,
      }, TokenData: res?.data.TokenData,
    }))
    dispatch(push('/'))
    dispatch(updateFilter({
      resourceIds: [...res?.data?.TokenData?.TokenClaims?.Resources, -1] || []
    }))
    return {
      userInfo: {
        ...userRes.data?.profile,
        photoUrl: userRes.data?.photoUrl,
        backgroundPhotoUrl: userRes.data?.backgroundPhotoUrl,
      }, TokenData: res?.data.TokenData,
    }
  } catch (e) {
    toast.error(e.message)
  } finally {
    dispatch(setShowGlobalIndicator(false))
  }
})

export const getUserInfoThunk = createAsyncThunk('auth/getUserInfo', async (data, {getState}) => {
  try {
    const res = await getUserInfo(data)
    return {
      ...res.data?.profile, photoUrl: res.data?.photoUrl, backgroundPhotoUrl: res.data?.backgroundPhotoUrl,
    }
  } catch (e) {
  }
})

export const getUserFlaggedDocsThunk = createAsyncThunk('auth/getUserFlaggedDocs', async (data, {getState}) => {
  try {
    const res = await getUserFlaggedDocs(data)
    return res.data
  } catch (e) {
  }
},)


export const getUserRatingsDocsThunk = createAsyncThunk('auth/getUserRatingDocs', async (data, {getState}) => {
  try {
    const res = await getUserRatingDocs(data)
    return res.data
  } catch (e) {
  }
},)
