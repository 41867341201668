export const bodySearch = {
  fieldTuningList: [
    {
      queryFunc: 'Accept Phrase',
      docField: 'Name',
      included: true,
      boost: 100,
      fuzzy: 0,
    },
    {
      queryFunc: 'Accept Phrase',
      docField: 'Overview',
      included: true,
      boost: 50,
      fuzzy: 0,
    },
    {
      queryFunc: 'Accept Phrase',
      docField: 'Contents',
      included: false,
      boost: 10,
      fuzzy: 0,
    },
    {
      queryFunc: 'Exact Match',
      docField: 'Name',
      included: true,
      boost: 10,
      fuzzy: 0,
    },
    {
      queryFunc: 'Exact Match',
      docField: 'Overview',
      included: false,
      boost: 100,
      fuzzy: 0,
    },
    {
      queryFunc: 'Exact Match',
      docField: 'Contents',
      included: false,
      boost: 100,
      fuzzy: 0,
    },
    {
      queryFunc: 'Prefix',
      docField: 'Name',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'Prefix',
      docField: 'Overview',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'Prefix',
      docField: 'Contents',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'WildCard',
      docField: 'Name',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'WildCard',
      docField: 'Overview',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'WildCard',
      docField: 'Contents',
      included: false,
      boost: 1,
      fuzzy: 0,
    },
    {
      queryFunc: 'Fuzzy',
      docField: 'Name',
      included: false,
      boost: 1,
      fuzzy: 1,
    },
    {
      queryFunc: 'Fuzzy',
      docField: 'Overview',
      included: false,
      boost: 1,
      fuzzy: 1,
    },
    {
      queryFunc: 'Fuzzy',
      docField: 'Contents',
      included: false,
      boost: 1,
      fuzzy: 1,
    },
  ],
  learning: ['0'],
  flag: '2',
  flaggedDocIds: [],
  unFlaggedDocIds: [],
  levelIds: ['0'],
  resourceIds: [0],
  contentTypeIds: [3, 4],
  contentIds: [],
  docIds: [],
  labels: [],
  versionName: [],
  pagination: {
    pageNo: 1,
    pageSize: 10,
  },
  hasContents: 2,
}

export const ContentType = {
  video: 3,
  article: 4,
}

export const DataContentType = {
  2: {
    title: 'All',
    value: 'all',
  },
  3: {
    title: 'Video',
    value: 3,
  },
  4: {
    title: 'Article',
    value: 4,
  },
}

export const DataFlags = {
  0: {
    title: 'All',
    value: '0',
  },
  1: {
    title: 'Flagged',
    value: '1',
  },
  2: {
    title: 'Unflagged',
    value: '2',
  },
}
