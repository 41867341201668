import {createAsyncThunk} from '@reduxjs/toolkit'
import {getChannels, getResources} from '../../utilities/ApiManage'

export const getResourcesThunk = createAsyncThunk('app/resources', async payload => {
  try {
    const res = await getResources()
    if (!res.ok) throw new Error(res.message)
    return res.data.reduce((a, b) => ({...a, [b.docId]: b}), {})
  } catch (e) {}
})

export const getChannelsThunk = createAsyncThunk('app/channels', async payload => {
  try {
    const res = await getChannels()
    if (!res.ok) throw new Error(res.message)
    return res.data.reduce((a, b) => ({...a, [b.docId]: b}), {})
  } catch (e) {}
})
