import {createSlice} from '@reduxjs/toolkit'
import {getChannelsThunk, getResourcesThunk} from '../thunks/appServices'

const initialState = {
  authForm: 'login', //'forgot | 'signUp' | 'login',
  showGlobalIndicator: false,
  libraries: {
    data: [],
  },
  channels: {
    data: [],
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuthForm: (state, action) => {
      state.authForm = action.payload
    },
    setShowGlobalIndicator: (state, action) => {
      state.showGlobalIndicator = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getResourcesThunk.fulfilled, (state, action) => {
      state.libraries = action.payload
    })

    builder.addCase(getChannelsThunk.fulfilled, (state, action) => {
      state.channels = action.payload
    })
  },
})

export const {redirect, setShowGlobalIndicator, setAuthForm} = appSlice.actions
export default appSlice.reducer
