import {
  getWithTimeout, postWithTimeout, deleteWithTimeout, putWithTimeout, patchWithTimeout,
} from './networking'
import {app_secret, app_token} from '../constants/constants'

let accessToken = null
let mobileSession = ''

export function setMobileSession(s) {
  mobileSession = s
}

export function getMobileSession() {
  return mobileSession
}

export function setAccessToken(token) {
  accessToken = token
}

export function getAccessToken() {
  return accessToken
}

export function clearToken() {
  accessToken = ''
}

const getFormData = (params) => {
  const formData = new FormData()
  Object.keys(params).forEach((item) => {
    formData.append(`${item}`, params[item])
  })
  return formData
}

export function getWithCheckingToken(api, headers) {
  if (!headers || !headers['token']) headers = {
    ...headers, Authorization: `Bearer ${accessToken}`,
  }
  return getWithTimeout(api, headers)
}

export function patchWithCheckingToken(api, headers, body) {
  if (!headers || !headers['token']) headers = {
    ...headers, Authorization: `Bearer ${accessToken}`,
  }
  return patchWithTimeout(api, headers, body)
}

export function postWithCheckingToken(api, headers, body) {
  if (!headers || !headers['token']) headers = {
    ...headers, Authorization: `Bearer ${accessToken}`,
  }
  return postWithTimeout(api, headers, body)
}

export function putWithCheckingToken(api, headers, body) {
  if (!headers || !headers['token']) headers = {
    ...headers, Authorization: `Bearer ${accessToken}`,
  }
  return putWithTimeout(api, headers, body)
}

export function deleteWithCheckingToken(api, headers, body) {
  if (!headers || !headers['token']) headers = {
    ...headers, Authorization: `Bearer ${accessToken}`,
  }
  return deleteWithTimeout(api, headers, body)
}
