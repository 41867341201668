/*eslint-disable*/

import {getMobileSession} from './NetworkingAuth'

function timeout(request, duration, api) {
  return new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      resolve({})
    }, duration)

    request.then(
      res => {
        clearTimeout(timeout)
        timeout = null
        resolve(res)
      },
      err => {
        clearTimeout(timeout)
        timeout = null
        resolve({})
      },
    )
  })
}

export function getWithTimeout(api, headers) {
  return timeout(get(api, headers), 120000, api)
}

export function get(api, headers) {
  return fetch(api, {
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      ...headers,
    },
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok,
          ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function patchWithTimeout(api, headers, body) {
  return timeout(patch(api, headers, body), 120000, api)
}

export function patch(api, headers, body) {
  if (typeof body === 'object' && body.constructor !== FormData) body = JSON.stringify(body)
  return fetch(api, {
    method: 'patch',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    body: body,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok,
          ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function postWithTimeout(api, headers, body) {
  return timeout(post(api, headers, body), 240000, api)
}

export function post(api, headers, body) {
  if (typeof body === 'object' && body.constructor !== FormData) body = JSON.stringify(body)
  let heads = {}
  if (headers['Content-Type'])
    heads = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    }
  else
    heads = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  return fetch(api, {
    method: 'post',
    headers: heads,
    body: body,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok,
          ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function deleteWithTimeout(api, headers, body) {
  return timeout(_delete(api, headers, body), 120000, api)
}

export function _delete(api, headers, body) {
  if (typeof body === 'object' && body.constructor !== FormData) body = JSON.stringify(body)

  let heads = {}
  if (headers['Content-Type'])
    heads = {
      ...headers,
      Accept: 'application/json',
    }
  else
    heads = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

  return fetch(api, {
    method: 'delete',
    headers: heads,
    body: body,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok,
          ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function putWithTimeout(api, headers, body) {
  return timeout(put(api, headers, body), 120000, api)
}

export function put(api, headers, body) {
  if (typeof body === 'object' && body.constructor !== FormData) body = JSON.stringify(body)

  let heads = {}
  if (headers['Content-Type'])
    heads = {
      ...headers,
      Accept: 'application/json',
    }
  else
    heads = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  return fetch(api, {
    method: 'put',
    headers: heads,
    body: body,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok,
          ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}
