import { useState, useEffect } from "react";
import { Span } from "./Text";
import { faScaleBalanced } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTermsAndConditions } from "../../src/utilities/ApiManage";
import packageJson from "../../package.json";
import CustomModal from "./CustomModal";
import { Input } from "antd";

const FooterComponent = () => {
  const [show, setShow] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState("");

  const { TextArea } = Input;

  useEffect(() => {
    getTermsAndConditions().then((res) => {
      if (res.ok) {
        setTermAndCondition(res?.data?.Data?.TermsContent);
      }
    });
  }, []);

  return (
    <>
      <div className="w-full p-5 bg-navy flex gap-9 justify-between overflow-hidden">
        <div className="flex gap-9">
          <Span className="text-white">© 2022 BepozGlobal PTY LTD</Span>

          <a onClick={setShow}>
            <FontAwesomeIcon
              icon={faScaleBalanced}
              size="1x"
              color="white"
              // className={'mr-4 cursor-pointer'}
            />
            <span className="text-white p-2">Legals and Policies</span>
          </a>
        </div>
        <div className="text-white overflow-hidden place-self-end">{`v.${packageJson.version}`}</div>
      </div>
      <CustomModal
        visible={show}
        onCancel
        onClose={setShow}
        title="Legals and Policies"
      >
        <TextArea
          className="rounded-xl"
          autoSize={{ maxRows: 30 }}
          readOnly
          defaultValue={termAndCondition}
        />
      </CustomModal>
    </>
  );
};

export default FooterComponent;
