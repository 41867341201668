// export const API_URI = ' https://mybepoz.com/'
export const API_URI = '.azurewebsites.net/'
export const APPLICATION_URL = 'http://localhost:3000'
export const app_secret = '2da489f7ac6cce34ddcda02ef126270b'
export const app_token = '12a3e1a732ee'
export const ERROR_STATUS = [401, 403, 410]
export const SUCCESS = 'ok'
export const MaintainStatus = 503


//production api
export const SUB_DOMAIN = {
  USER: 'https://mybepozuserapi',
  AUTH: 'https://mybepoz',
  SEARCH: 'https://mybepozsearchapi',
  CONTENT: 'https://mybepozcontentapi',
  MEDIA: 'https://mybepozmediaapi',
}

//staging api
// export const SUB_DOMAIN = {
//   USER: 'https://mybepozstaginguserapi',
//   AUTH: 'https://mybepozstaging',
//   SEARCH: 'https://mybepozstagingsearchapi',
//   CONTENT: 'https://mybepozstagingcontentapi',
//   MEDIA: 'https://mybepozstagingmediaapi',
// }

//development api
// export const SUB_DOMAIN = {
//   USER: 'https://mybepozsandboxuserapi',
//   AUTH: 'https://mybepozsandbox',
//   SEARCH: 'https://mybepozsandboxsearchapi',
//   CONTENT: 'https://mybepozsandboxcontentapi',
//   MEDIA: 'https://mybepozsandboxmediaapi',
// }

export const UserRoles = {
  endUser: 'role-end-user',
  guestUser: 'role-guest-user',
}

export const defaultBackgroundImage =
  'https://mybepoz.blob.core.windows.net/users/0_profileheader_default.jpg'

export const DefaultCredential = {
  email: 'mybepozUser@bepozglobal.com',
  password: 'Do4s@fet'
}
