import { Image, Input, Layout } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilter } from '../store/reducers/contentServices'
import { useState } from 'react'
import { Link, useHistory } from "react-router-dom";

const {Header} = Layout

const HeaderComponent = ({}) => {
  const dispatch = useDispatch()
  const navigate = useHistory()
  const filter = useSelector(state => state.contentServices.filter)
  const [searchTerm, setSearchTerm] = useState(filter.searchTerm || '')
  const isEndUser = useSelector(state => state.authServices.isEndUser)



  const handleSearchData = text => {
    dispatch(updateFilter({
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      searchTerm: text,
    }),)
    navigate.push('/')
  }

  return (<Header className="w-screen px-4 sm:px-10 bg-navy flex justify-between items-center h-16">
      {/* Logo */}
      <div className="hidden sm:flex">
        <Link to="/" passHref>
          <a className="flex flex-col w-36">
            <Image src={require('../assets/myBepozLogo.png')} height={40} width={188} layout="responsive" alt=""
                   preview={false}/>
          </a>
        </Link>
      </div>

      {/* Search Bar */}
      <div className="flex flex-1 justify-center">
        {isEndUser && (<Input
            className="bg-aqua search rounded-lg mx-4 sm:mx-10 lg:w-1/2 border-0 focus:border-0"
            prefix={<FontAwesomeIcon
              onClick={() => handleSearchData(searchTerm)}
              icon={faSearch}
              color="white"
              size="lg"
              className="mr-1"
            />}
            placeholder="Type here to search"
            enterKeyHint="search"
            value={searchTerm}
            size="small"
            onChange={e => setSearchTerm(e.target.value)}
            onPressEnter={e => {
              handleSearchData(e.target.value)
            }}
          />)}
      </div>
    </Header>)
}

export default HeaderComponent
