import {configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from 'redux-persist'
import rootReducer from './reducers'
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root', storage, blacklist: ['appServices', 'contentServices']
}


const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat([routerMiddleware(history)]), // middleware: [logger]
})

export const persistor = persistStore(store)
